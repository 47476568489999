<template>
  <vx-card title="Pickup POD">
    <div class="vx-row" style="width: 100%">
      <div class="vx-col">
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>SO Data</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              readonly
              :value="data.SalesOrder.Code"
              class="w-full"
            ></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>DO Data</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input readonly :value="data.Code" class="w-full"></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>POD Number</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              readonly
              :value="data.ProofOfDelivery.Code"
              class="w-full"
            ></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>POD Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              readonly
              :value="data.ProofOfDelivery.Date"
              class="w-full"
            ></vs-input>
          </div>
        </div>

        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>POD Status</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- <vs-input readonly :value="data.ProofOfDelivery.Status" class="w-full"></vs-input> -->
            <multiselect
              v-model="data.ProofOfDelivery.status"
              :options="status"
              placeholder="Type to search"
              track-by="id"
              label="text"
              :max-height="125"
              @select="changeStatus"
              :option-disabled="isOptionDisabled"
            >
              <template slot="option" slot-scope="{ option }">
                <span
                  :class="{
                    'is-disabled':
                      option.text == 'Partial Complete' && !isAllowPartial,
                  }"
                  >{{ option.text }}</span
                >
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>POD Posting Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              v-model="selectedPostingDate"
              format="yyyy-MM-dd"
              input-class="input-class"
            ></datepicker>
          </div>
        </div>
      </div>
    </div>
    <br />
    <table class="table table-stripe" v-if="renderTable">
      <thead>
        <th>SKU Code</th>
        <th>SKU Name</th>
        <th>HU</th>
        <th>DO Qty</th>
        <th>Batch Number</th>
        <th>Expired Date</th>
        <th>Rejected Qty</th>
        <th>Accepted Qty</th>
        <th>Reason</th>
        <th>Note (optional)</th>
      </thead>
      <tbody>
        <tr :key="indextr" v-for="(tr, indextr) in data.DeliveryOrderLine">
          <td>{{ tr.PackingLine.SkuCode }}</td>
          <td>{{ tr.PackingLine.ItemName }}</td>
          <td>{{ tr.PackingLine.ItemUnit }}</td>
          <td>{{ tr.PackingLine.Amount }}</td>
          <td>{{ tr.PackingLine.Batch }}</td>
          <td>{{ tr.PackingLine.ExpiredDate }}</td>
          <td>
            <vs-input
              :disabled="[1, 4].includes(data.ProofOfDelivery.status.id)"
              v-model="tr.ProofOfDeliveryLine.ReturnQuantity"
              @input="(e) => calculateQuantity(e, indextr)"
            ></vs-input>
          </td>
          <!-- <td>{{ tr.PackingLine.Amount - tr.ProofOfDeliveryLine.ReturnQuantity }}</td> -->
          <td>{{ tr.ProofOfDeliveryLine.AdjustedQuantity }}</td>
          <!-- <td>{{ tr.ProofOfDeliveryLine.ReasonID }}</td> -->
          <td>
            <multiselect
              :disabled="data.ProofOfDelivery.status.id == 1"
              v-model="tr.ProofOfDeliveryLine.reason"
              :options="reasons"
              placeholder="Type to search"
              track-by="id"
              label="reason"
              :max-height="125"
              @select="logSelect"
            >
            </multiselect>
          </td>
          <!-- <td>{{ tr.ProofOfDeliveryLine.Note }}</td> -->
          <td>
            <vs-input
              :disabled="data.ProofOfDelivery.status.id == 1"
              v-model="tr.ProofOfDeliveryLine.Note"
            ></vs-input>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <div class="mb-6 space-x-1 flex">
      <div class="">
        <vs-button
          color="primary"
          @click="handleSave(false)"
          :disabled="data.ProofOfDelivery.Status == 4"
          >Save</vs-button
        >
      </div>
      <div class="">
        <vs-button
          color="success"
          @click="handleSave(true)"
          :disabled="data.ProofOfDelivery.Status == 4"
          >Save & Confirm</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
// import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
// import Date from "./component/Date.vue";

export default {
  components: {
    // Date,
    // flatPickr,
    Datepicker,
  },
  props: {
    do_id: Number,
  },
  data() {
    return {
      selectedPostingDate: moment().toDate(),
      status: [
        {
          id: 0,
          text: "Open",
          $isDisabled: false,
        },
        {
          id: 1,
          text: "Complete",
          $isDisabled: false,
        },
        {
          id: 2,
          text: "Partial Complete",
          $isDisabled: false,
        },
        {
          id: 4,
          text: "Failed Cancel",
          $isDisabled: false,
        },
      ],
      reasons: [],
      renderTable: true,
      table: this.tableDefaultState(),
      data: {
        SalesOrder: {},
        ProofOfDelivery: {},
      },
      // data: null,
      pickingListNumber: null,
      checkedAll: false,
      checked: [],
      rdd: moment().format("D MMMM yyyy"),
      // configFlatPickr: {
      //   altFormat: "F j, Y",
      //   dateFormat: "j F Y",
      //   enableTime: false,
      //   plugins: [new ConfirmDatePlugin()],
      // },
      isAllowPartial: false,
    };
  },
  computed: {
    formatDate: () => {
      return (val) => (val ? moment.utc(val).format("YYYY-MM-DD") : "");
    },
  },
  methods: {
    isOptionDisabled(option) {
      console.log(option.name == "Partial Complete" && !this.isAllowPartial);
      return option.name == "Partial Complete" && !this.isAllowPartial;
    },
    calculateQuantity(event, idx) {
      const returnQuantity = parseInt(event || "0");
      const podLine = this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine;
      this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine.AdjustedQuantity =
        podLine.Quantity - returnQuantity;
    },
    logSelect(val) {
      // console.log(val)
      this.renderTable = false;
      this.$nextTick(() => (this.renderTable = true));
    },
    tableDefaultState() {
      return {
        data: [
          {
            SKUCode: "89898989",
            SKUName: "ULI JONGGOL",
            HU: "uwowowow",
            Quantity: "98246",
            storageArea: "MW9NF9P",
            batchNumber: "823748280374",
            expDate: "24 JANUARI 2027",
          },
        ],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    getReason(reason_group) {
      console.log(reason_group);
      if (reason_group) {
        this.$http
          .get(`/api/wms/v1/master/reason`, {
            params: { reason_group },
          })
          .then((resp) => {
            if (resp.code == 200) {
              console.log("reason", resp.data);
              this.reasons = resp.data.records;
              this.data.DeliveryOrderLine.forEach((dol, idx) => {
                console.log("findReason");
                this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine.reason =
                  this.findReason(dol.ProofOfDeliveryLine.ReasonID);
              });
            } else {
              //
            }
          });
      } else {
        this.reasons = [];
      }
    },
    findStatus(statusId) {
      return this.status.find((s) => s.id == statusId);
    },
    findReason(reasonId) {
      console.log("reasonId", reasonId);
      console.log(this.reasons);
      const res = this.reasons.find((r) => r.id == reasonId);
      console.log("res", res);
      return res;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(`/api/wms/v1/simple-outbound-planner/do/${this.do_id}`)
        .then((resp) => {
          if (resp.code == 200) {
            this.data = resp.data;
            this.data.ProofOfDelivery.status = this.findStatus(
              this.data.ProofOfDelivery.StatusDelivery
            );
            this.changeStatus(this.data.ProofOfDelivery.status);
            this.data.ProofOfDelivery.Date =
              this.data.ProofOfDelivery.Date.split("T")[0];
            this.data.DeliveryOrderLine.forEach((dol, idx) => {
              this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine.reason = {
                id: 0,
              };
            });

            this.isAllowPartial =
              this.data.SalesOrder.AllowPartial == 1 ? true : false;

            if (!this.isAllowPartial) {
              this.status.forEach((s) => {
                if (s.id == 2) {
                  s.$isDisabled = true;
                }
              });
            }

            console.log(this.status);

            this.selectedPostingDate = moment(
              this.data.ProofOfDelivery.PostingDate
            ).toDate();
            // this.changeStatus(this.data.ProofOfDelivery.status)
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    emitClose() {
      this.$emit("close");
    },
    handleSave(confirm) {
      console.log("save");
      if (confirm) {
        if (this.data.ProofOfDelivery.status.id == 0) {
          this.$vs.notify({
            title: "Warning",
            text: "Please Update POD Status first",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
          });
          return;
        }
      }

      if (this.data.ProofOfDelivery.status.id == 2) {
        var totalReturn = 0;
        this.data.DeliveryOrderLine.forEach((dol) => {
          totalReturn += dol.ProofOfDeliveryLine.ReturnQuantity = parseInt(
            dol.ProofOfDeliveryLine.ReturnQuantity || "0"
          );
        });
        if (totalReturn == 0) {
          this.$vs.notify({
            title: "Warning",
            text: "Please input return quantity minimum 1 item",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
          });
          return;
        }
      }

      if (this.data.ProofOfDelivery.status.id == 2) {
        var hasRejectedNoReason = 0;
        this.data.DeliveryOrderLine.forEach((dol) => {
          if (
            dol.ProofOfDeliveryLine.ReturnQuantity != 0 &&
            dol.ProofOfDeliveryLine.reason == undefined
          ) {
            hasRejectedNoReason++;
          }
        });

        if (hasRejectedNoReason > 0) {
          this.$vs.notify({
            title: "Warning",
            text: "Please chose reason for rejected item",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
          });
          return;
        }
      }

      if (this.data.ProofOfDelivery.status.id == 4) {
        var noReasonTotal = 0;
        this.data.DeliveryOrderLine.forEach((dol) => {
          if (!dol.ProofOfDeliveryLine.reason) {
            noReasonTotal++;
          }
        });

        if (noReasonTotal > 0) {
          this.$vs.notify({
            title: "Warning",
            text: "Please chose reason for all rejected item",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
          });
          return;
        }
      }

      console.log("save sini");
      const podLines = this.data.DeliveryOrderLine.map((dol) => ({
        id: dol.ProofOfDeliveryLine.id,
        quantity: dol.ProofOfDeliveryLine.Quantity,
        adjusted_quantity: dol.ProofOfDeliveryLine.AdjustedQuantity,
        return_quantity: parseInt(
          dol.ProofOfDeliveryLine.ReturnQuantity || "0"
        ),
        reason_id: dol.ProofOfDeliveryLine.reason
          ? dol.ProofOfDeliveryLine.reason.id
          : null,
        note: dol.ProofOfDeliveryLine.Note,
      }));

      console.log("save sini 2");
      console.log(podLines);

      var emitClose = this.emitClose;
      var selectedPostingDate = this.selectedPostingDate;
      var data = this.data;
      var do_id = this.do_id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Pickup Save Confirmation`,
        text: "Please confirm to process this data",
        accept: function () {
          this.$vs.loading();
          this.$http
            .patch(`/api/wms/v1/simple-outbound-planner/pod/${do_id}`, {
              status_delivery: data.ProofOfDelivery.status.id,
              pod_lines: podLines,
              confirm: confirm,
              posting_date: moment(selectedPostingDate).format("YYYY-MM-DD"),
            })
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully proceessed",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });

                emitClose();
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });

      // this.$vs.loading();
      // this.$http
      //   .patch(`/api/wms/v1/simple-outbound-planner/pod/${this.do_id}`, {
      //     status_delivery: this.data.ProofOfDelivery.status.id,
      //     pod_lines: podLines,
      //     confirm: confirm,
      //     posting_date: moment(this.selectedPostingDate).format("YYYY-MM-DD"),
      //   })
      //   .then((resp) => {
      //     if (resp.code == 200) {
      //       this.$vs.loading.close();
      //       this.$vs.notify({
      //         title: "Success",
      //         text: resp.message,
      //         iconPack: "feather",
      //         icon: "icon-alert-circle",
      //         color: "success",
      //         position: "top-right",
      //       });
      //       this.$emit("close");
      //     } else {
      //       this.$vs.loading.close();
      //       this.$vs.notify({
      //         title: "Failed",
      //         text: resp.message,
      //         iconPack: "feather",
      //         icon: "icon-alert-circle",
      //         color: "danger",
      //         position: "top-right",
      //       });
      //     }
      //   });
    },
    changeStatus(val) {
      let reasonGroup = "";
      if (val.id == 1) {
        this.data.DeliveryOrderLine.forEach((dol) => {
          dol.ProofOfDeliveryLine.ReturnQuantity = 0;
          dol.ProofOfDeliveryLine.AdjustedQuantity =
            dol.ProofOfDeliveryLine.Quantity;
        });
      }
      if (val.id == 2) {
        reasonGroup = "POD Partial";
      } else if (val.id == 4) {
        reasonGroup = "POD Failed";
        this.data.DeliveryOrderLine.forEach((dol) => {
          dol.ProofOfDeliveryLine.ReturnQuantity =
            dol.ProofOfDeliveryLine.Quantity;
          dol.ProofOfDeliveryLine.AdjustedQuantity = 0;
        });
      }
      this.getReason(reasonGroup);
      this.logSelect(val);
    },
  },
  mounted() {
    console.log("mounted");
    // this.getReason()
    this.getData();
  },
  watch: {
    // detail() {
    //     this.getData();
    // },
    checkedAll(val) {
      if (val) {
        this.selectedRouteAssignmentToAssign = [];
        this.table.data.forEach((el, index) => {
          this.checked[index] = true;
          this.selectedRouteAssignmentToAssign.push(el);
        });
      } else {
        if (
          this.selectedRouteAssignmentToAssign.length == this.checked.length
        ) {
          this.checked.forEach((el, index) => {
            this.checked[index] = false;
          });
          this.selectedRouteAssignmentToAssign = [];
        }
      }
    },
  },
};
</script>
