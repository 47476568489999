<template>
  <vx-card title="">
    <div class="flex justify-between mb-2">
      <h3>Handover Data Edit</h3>
      <vs-button @click="$emit('close')">Back</vs-button>
    </div>
    <div class="vx-row" style="width: 100%">
      <div class="vx-col sm:w-1/2">
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Shipment Doc Number</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              disabled
              :value="data.TransportPlan.ShipmentNumber"
              class="w-full"
            ></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Handover Document</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- <vs-input readonly :value="pickingListNumber" class="w-full"></vs-input> -->
            <vs-input disabled :value="data.Code" class="w-full"></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Vehicle Number</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- <vs-input readonly :value="data.Vehicle.Number" class="w-full"></vs-input> -->
            <vs-input
              disabled
              :value="data.TransportPlan.VehicleNumber"
              class="w-full"
            ></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Driver</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- <vs-input readonly :value="data.Vehicle.DriverName" class="w-full"></vs-input> -->
            <vs-input
              disabled
              :value="data.TransportPlan.DriverName"
              class="w-full"
            ></vs-input>
          </div>
        </div>
      </div>
      <div class="vx-col sm:w-1/2">
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span># of Delivery Note</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              disabled
              :value="data.Loading ? data.Loading.length : 0"
              class="w-full"
            ></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span># of COD</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input disabled :value="data.COD" class="w-full"></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Handover Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- <Date></Date> -->
            <flat-pickr
              disabled
              style="width: 100%"
              :config="configFlatPickr"
              v-model="rdd"
            ></flat-pickr>
          </div>
        </div>
      </div>
    </div>
    <br />
    <vs-tabs>
      <vs-tab label="Loading List View">
        <table class="table table-stripe" v-if="renderTable">
          <thead>
            <th sort-key="">SKU Code</th>
            <th sort-key="">SKU Name</th>
            <th sort-key="">HU</th>
            <th sort-key="">Qty</th>
            <th>
              <vs-checkbox disabled v-model="checkAll" @click="addCheckAll"
                >Handed-over</vs-checkbox
              >
            </th>
          </thead>
          <tbody>
            <!-- <template v-for="(tr, indextr) in data.HandoverLine"> -->
            <!-- <tr :key="`${indextr}-${indext}`" v-for="(d, indext) in tr.HandoverDeliveryLine"> -->
            <tr :key="indextr" v-for="(tr, indextr) in data.HandoverLine">
              <td>{{ tr.SkuCode }}</td>
              <td>{{ tr.ItemName }}</td>
              <td>{{ tr.ItemUnit }}</td>
              <td>{{ tr.Amount }}</td>
              <td>
                <vs-checkbox
                  disabled
                  @click="checkAll = false"
                  v-model="tr.IsHandedover"
                ></vs-checkbox>
              </td>
            </tr>
            <!-- </template> -->
          </tbody>
        </table>
        <br />
        <div>
          <h5>Customer Return Pickup</h5>
        </div>
        <vs-table stripe border :data="data.TransportPickup">
          <template slot="thead">
            <vs-th sort-key="">Customer Return Data</vs-th>
            <vs-th sort-key="">Pickup Document</vs-th>
            <vs-th sort-key="">Customer Data</vs-th>
            <vs-th sort-key="">Ship-to-Data</vs-th>
          </template>

          <template>
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data.TransportPickup"
            >
              <vs-td>{{ tr.SalesReturn.Code }}</vs-td>
              <vs-td>{{ formatDate(tr.PickupDate) }}</vs-td>
              <vs-td
                >{{ tr.SalesReturn.CustomerCode }}
                {{ tr.SalesReturn.CustomerName }}</vs-td
              >
              <vs-td>{{ tr.CustomerAddress }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <br />
        <div class="mb-6 space-x-1 flex">
          <!-- <div class="">
                        <vs-button color="primary" @click="handleSave(false)">Save as Draft</vs-button>
                    </div> -->
          <div class="">
            <vs-button color="success" @click="handleSave(true)"
              >Confirm to Complete</vs-button
            >
          </div>
        </div>
      </vs-tab>
      <vs-tab label="DO View">
        <table class="table table-stripe" v-if="renderTable">
          <thead>
            <th sort-key="">DN Number</th>
            <th sort-key="">SKU Name</th>
            <th sort-key="">HU</th>
            <th sort-key="">Qty</th>
            <th sort-key="">Storage Area</th>
            <th sort-key="">Batch Number</th>
            <th sort-key="">Expired Date</th>
          </thead>
          <tbody>
            <template v-for="(tr, indextr) in data.Loading">
              <tr
                :key="`${indextr}-${indext}`"
                v-for="(d, indext) in tr.Packing.PackingLine"
              >
                <td>{{ indext == 0 ? tr.Packing.DeliveryOrder.Code : "" }}</td>
                <td>{{ d.ItemName }}</td>
                <td>{{ d.ItemUnit }}</td>
                <td>{{ d.Amount }}</td>
                <td>{{ d.StorageCode }}</td>
                <td>{{ d.Batch }}</td>
                <td>{{ formatDate(d.ExpiredDate) }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

// import Date from "./component/Date.vue";
import moment from "moment";

export default {
  components: {
    // Date,
    flatPickr,
  },
  props: {
    handover_id: Number,
  },
  data() {
    return {
      renderTable: true,
      table: this.tableDefaultState(),
      data: {
        // Vehicle: {},
        // TransportPlanLine: [],
      },
      // data: null,
      pickingListNumber: null,
      checkedAll: false,
      checked: [],
      rdd: moment().format("D MMMM yyyy"),
      configFlatPickr: {
        altFormat: "F j, Y",
        dateFormat: "j F Y",
        enableTime: false,
        plugins: [new ConfirmDatePlugin()],
      },
      checkAll: false,
    };
  },
  computed: {
    formatDate: () => {
      return (val) => (val ? moment.utc(val).format("YYYY-MM-DD") : "");
    },
  },
  methods: {
    addCheckAll() {
      // if (!this.checkAll) {
      this.data.HandoverLine.forEach(
        (hl) => (hl.IsHandedover = !this.checkAll)
      );
      // }
    },
    tableDefaultState() {
      return {
        data: [
          {
            SKUCode: "89898989",
            SKUName: "ULI JONGGOL",
            HU: "uwowowow",
            Quantity: "98246",
            storageArea: "MW9NF9P",
            batchNumber: "823748280374",
            expDate: "24 JANUARI 2027",
          },
        ],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(
          `/api/wms/v1/simple-outbound-planner/handover-data/${this.handover_id}`
        )
        .then((resp) => {
          if (resp.code == 200) {
            console.log(resp.data);
            this.data = resp.data; //.map
            this.rdd = moment(this.data.Date).format("D MMMM yyyy");
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    // handleSave(confirm) {
    //   console.log("save");
    //   const handover = this.data.HandoverLine.map((hl) => ({
    //     id: hl.id,
    //     is_handedover: hl.IsHandedover,
    //   }));
    //   console.log(handover);
    //   this.$vs.loading();
    //   this.$http
    //     // .patch("/api/wms/v1/simple-outbound-planner/handover", {delivery_plan_id: [null], to_dp: true})
    //     .post("/api/wms/v1/simple-outbound-planner/handover", {
    //       handover_id: this.handover_id,
    //       handover: handover,
    //       confirm,
    //     })
    //     .then((resp) => {
    //       if (resp.code == 200) {
    //         this.$vs.loading.close();
    //         // this.getData()
    //         this.$vs.notify({
    //           title: "Success",
    //           text: resp.message,
    //           color: "success",
    //           position: "top-right",
    //         });
    //         this.$emit("close");
    //       } else {
    //         this.$vs.loading.close();
    //         this.$vs.notify({
    //           title: "Failed",
    //           text: resp.message,
    //           color: "danger",
    //           position: "top-right",
    //         });
    //       }
    //     });
    // },

    emitClose() {
      this.$emit("close");
    },

    handleSave(confirm) {
      var handoverID = this.handover_id;
      var emitClose = this.emitClose;
      var getData = this.getData;
      const handover = this.data.HandoverLine.map((hl) => ({
        id: hl.id,
        is_handedover: hl.IsHandedover,
      }));
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Save Confirmation`,
        text: "Please confirm to process this data",
        accept: function () {
          this.$vs.loading();
          this.$http
            .post("/api/wms/v1/simple-outbound-planner/handover", {
              handover_id: handoverID,
              handover: handover,
              confirm,
            })
            .then((resp) => {
              this.$vs.loading.close();
              getData();
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully proceessed",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
                emitClose();
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },
  },
  mounted() {
    console.log("mounted");
    this.getData();
  },
  watch: {
    // detail() {
    //     this.getData();
    // },
    checkedAll(val) {
      if (val) {
        this.selectedRouteAssignmentToAssign = [];
        this.table.data.forEach((el, index) => {
          this.checked[index] = true;
          this.selectedRouteAssignmentToAssign.push(el);
        });
      } else {
        if (
          this.selectedRouteAssignmentToAssign.length == this.checked.length
        ) {
          this.checked.forEach((el, index) => {
            this.checked[index] = false;
          });
          this.selectedRouteAssignmentToAssign = [];
        }
      }
    },
  },
};
</script>
